.callForPapers__main {
  width: 640px;
  height: auto;
  margin: 2rem auto;
  padding: 10px;
  position: relative;

  .callForPapers__header {
    text-align: center;
  }

  .callForPapers__steps {
    margin-bottom: 2rem;

    :global(.p-steps-current .p-steps-number) {
      background: #8dd0ff !important;
    }

    :global(.p-steps-number) {
      background: var(--surface-ground) !important;
    }
  }

  .callForPapers__spinner {
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .callForPapers__card {
    position: relative;

    :global(.p-card-body) {
      padding: 0 0.5rem 1rem 0.5rem;
    }

    :global(.p-card-header) {
      margin-bottom: 1.5rem;
    }

    .callForPapers__card_header {
      .callForPapers__card_cancelButton {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }

    padding: 2rem;

    .callForPapers__card_email {
      color: var(--text-color-secondary);
    }
  }

  .callForPapers__card_opacity {
    opacity: .3;
  }

  .callForPapers__form {
    :global(.p-field) {
      margin-bottom: 2rem;
    }

    .callForPapers__form_label_span {
      font-size: 10px;
      color: var(--blue-400);
    }
  }
}

@media (max-width: 640px) {
  .callForPapers__main {
    width: auto;
  }
}

@media (max-width: 400px) {
  .callForPapers__card {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@media (max-width: 460px) {
  .fixed_field {
    padding-top: 0.5rem;

    :global(.p-float-label .p-inputtext:focus ~ label),
    :global(.p-float-label .p-filled ~ label) {
      top: 0;
      transform: translateY(-100%);
    }
  }
}
