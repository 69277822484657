@import "../../../styles/variables";
@import "../../../styles/mixins";

.layout__topBar {
  position: fixed;
  height: 5rem;
  z-index: 997;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 2rem;
  background-color: var(--surface-card);
  transition: left $transitionDuration;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .02), 0 0 2px rgba(0, 0, 0, .05), 0 1px 4px rgba(0, 0, 0, .08);

  .layout__topbar_logo {
    display: flex;
    align-items: center;
    color: var(--surface-900);
    font-size: 1.5rem;
    font-weight: 500;
    border-radius: 12px;

    img {
      margin-right: .5rem;
    }

    &:focus {
      @include focused();
    }

    .layout__topbar_home_button {
      display: none;
    }
  }

  .layout__topbar_button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--text-color-secondary);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    transition: background-color $transitionDuration;

    &:hover {
      color: var(--text-color);
      background-color: var(--surface-hover);
    }

    &:focus {
      @include focused();
    }

    i {
      font-size: 1.5rem;
    }

    span {
      font-size: 1rem;
      display: none;
    }

  }

  .layout__topbar_displayButton {
    span {
      font-size: 1rem;
      display: block;
    }
  }

  .layout__topbar_profile_logo_admin {
    border: solid 1px saddlebrown;
  }

  .layout__topbar_profile_logo_admin {
    border: solid 1px seagreen;
  }

  .layout__topBar_menuButton {
    margin-left: 2rem;
  }

  .layout__topBar_menuButton {
    display: none;

    i {
      font-size: 1.25rem;
    }
  }

  .layout__topbar_menu {
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;

    .layout__topbar_button {
      margin-left: 1rem;
    }
  }
}

@media (max-width: 460px) {
  .layout__topbar_logo {
    img {
      display: none !important;
    }

    .layout__topbar_home_button {
      display: inline-flex !important;
    }
  }
}
