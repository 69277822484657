
.CustomTextInput {
  display: flex;

  &__prefix {
    &_dark {
      background: #20262e;
      border: 1px solid #3f4b5b;
      color: rgba(255, 255, 255, 0.87);
      border-right-width: 0;
    }

    &_light {
      color: #495057;
      background: #ffffff;
      border: 1px solid #ced4da;
      border-right-width: 0;
    }

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    padding: 0.5rem 0.75rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    appearance: none;
    border-right-width: 0 !important;
    border-radius: 4px 0 0 4px;
    margin-right: -9px;

    /* Remove iOS zoom */
    user-select: none;

    box-shadow: none;

    &_focus {
      z-index: 1;
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
    }

    &_focus_dark {
      border-color: #8dd0ff;
    }

    &_focus_light {
      border-color: #007bff;
    }
  }

  &__input {
    min-width: 250px;
    border-radius: 0 4px 4px 0;
    border-left-width: 0 !important;
    box-shadow: none !important;

    :focus label {
      top: -0.75rem;
      font-size: 12px;
    }

    &_prefix {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  :global(.p-inputtext) {
    box-shadow: none !important;
  }
}

.FormTextField {
  :global {
    .p-float-label input:focus ~ label, .p-float-label input.p-filled ~ label {
      left: 0.1rem;
    }
  }

  :global {
    .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label {
      left: 0.1rem;
    }
  }

  :global {
    .p-float-label .p-inputwrapper-focus ~ label {
      left: 0.1rem;
    }
  }

  &__maxLen_label {
    position: absolute;
    top: -1.25rem;
    font-size: 12px;
    line-height: 1;
    right: 0;

    &_text {
      color: #6c757d;
    }
  }

  &__label_prefix label {
    left: 36px;
  }

  &__label_prefix_focus label {
    top: -0.75rem;
    font-size: 12px;
    left: 0.1rem;
  }

  &__subNotes {
    font-size: 0.8rem;

    &_dark {
      color: #f19ea6;
    }
    &_light {
      color: #dc3545;
    }
  }
}
