.errorMainContainer {
  width: 640px;
  height: auto;
  margin: 2rem auto;
  padding: 10px;
  position: relative;
}

.errorMainContainer__card {
  position: relative;

  :global(.p-card-body) {
    padding: 0 0.5rem 1rem 0.5rem;
  }

  :global(.p-card-header) {
    margin-bottom: 1.5rem;
  }

  .errorMainContainer__card_header {
  }

  padding: 2rem;
}
