@import "../../styles/variables";

.layout__container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding-top: 5rem;
  transition: margin-left $transitionDuration;
}

.layout__main {
  flex: 1 1 auto;
}
