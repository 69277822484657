@mixin focused() {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: var(--focus-ring);

    transition: box-shadow 0.2s;
}

@mixin focused-inset() {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset var(--focus-ring);

    transition: box-shadow 0.2s;
}

@mixin media-up($value) {
    @media (min-width: $value) {
        @content;
    }
}

@mixin media-down($value) {
    @media (max-width: $value - 1) {
        @content;
    }
}

@mixin media-between($from, $to) {
    @media (min-width: $from) and (max-width: $to - 1) {
        @content;
    }
}

@mixin useContainer {
    @include media-down(1200px) {
        padding-right: 20px;
        padding-left: 20px;
    }
    @include media-up(1200px) {
        padding-right: 32px;
        padding-left: 32px;
    }
    @include media-up(640px) {
        margin-right: auto;
        margin-left: auto;
    }
    @include media-between(640px, 960px) {
        max-width: 640px;
    }
    @include media-between(960px, 1200px) {
        max-width: 960px;
    }
    @include media-up(1200px) {
        max-width: 1200px;
    }
}

@mixin useVisuallyHidden() {
    position: absolute;

    width: 1px;
    height: 1px;
    overflow: hidden;

    white-space: nowrap;

    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
}

@mixin useTextEllipsis($rows: 1) {
    overflow: hidden;

    @if $rows == 1 {
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    @else {
        display: -webkit-box;

        -webkit-box-orient: vertical;
        -webkit-line-clamp: $rows;
    }
}
